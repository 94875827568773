import { GlobalState } from '@/store/interfaces'

export const agents = ({ agents }: GlobalState) => agents

export const groups = ({ groups }: GlobalState) => groups

export const config = ({ config }: GlobalState) => config

export const blockReportsViewByAgents = ({ config }: GlobalState) =>
  config?.login?.blockReportsViewByAgents || false

export const showReloadPageAlert = ({ reloadPage }: GlobalState) => reloadPage
